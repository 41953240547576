.as--sec {
    display: flex;
    z-index: 99999; /*this will keep the icon appearing all time, even when on input::focus*/
}
.as--wrp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px 14px 32px;
    grid-gap: 12px;
    gap: 12px;
    isolation: isolate;
    background: #FBFBFF !important;
    box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
    border-radius: 14px;
}
.as--c-b {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding-top: 40px;
}
.as--sec .modal-content {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}
.as--hdr {
    background: #FBFBFF !important;
}
.as--sec .btn-close {
    opacity: 1;
}
.as--sec .btn-close:hover {
    opacity: 0.5;
}
.as--sec .btn-close:active {
    border: none;
    outline: none;
}
.as--sec .modal-header {
    border-bottom: 0;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
.as--i {
    /* background: url(image.png); */
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    justify-items: center;
    align-items: center;
    text-align: center;

    margin-top: -44px;
}
.as--t {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000732;
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.as--s {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.as--btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;

    /* Primary - Blue */
    background: #40466a;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* White */
    color: #FBFBFF;
    margin-top: 16px;
    margin-bottom: 24px;

    border: 2px solid #40466a;
}
.as--wrp a:link { text-decoration: none; }
.as--wrp a:visited { text-decoration: none; }
.as--wrp a:hover { 
    text-decoration: none; 
    border: 2px solid #000732;
    background: #FBFBFF;
    color: #000732;
}
.as--wrp a:active { text-decoration: none; }
.as--footer {
    background-color: #FBFBFF!important;
}