*, *:before, *:after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Asap', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  /* White */
  background: #FBFBFF;
}

/* asap-300 - latin-ext_latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-display: swap; /* Dodaj tę linię */
  src: url('./fonts/Asap/asap-v26-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/Asap/asap-v26-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Asap/asap-v26-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Asap/asap-v26-latin-ext_latin-300.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-regular - latin-ext_latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-display: swap; /* Dodaj tę linię */
  src: url('./fonts/Asap/asap-v26-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/Asap/asap-v26-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Asap/asap-v26-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Asap/asap-v26-latin-ext_latin-regular.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-500 - latin-ext_latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-display: swap; /* Dodaj tę linię */
  src: url('./fonts/Asap/asap-v26-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/Asap/asap-v26-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Asap/asap-v26-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Asap/asap-v26-latin-ext_latin-500.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-700 - latin-ext_latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-display: swap; /* Dodaj tę linię */
  src: url('./fonts/Asap/asap-v26-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.svg#Asap') format('svg'); /* Legacy iOS */
}
/* asap-700 - latin-ext_latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-display: swap; /* Dodaj tę linię */
  src: url('./fonts/Asap/asap-v26-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Asap/asap-v26-latin-ext_latin-700.svg#Asap') format('svg'); /* Legacy iOS */
}

/* https://fonts.google.com/specimen/Asap+Condensed */
@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* src/navigation/TopNavCategories */
/* hideScrollbar */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-horizontal-scrolling-menu-center {
  align-items: center;
}
/* .react-horizontal-scrolling-menu--wrapper {
    align-items: center!important;
} */
.react-horizontal-scrolling-menu--wrapper {
  top: 0;
  bottom: 0;
  padding: 0px;
  margin: 0px;
}

.only-mobile {
  display: none!important;
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .react-horizontal-scrolling-menu-center {
      align-items: unset;
  }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .react-horizontal-scrolling-menu-center {
      align-items: unset;
  }
  .only-mobile {
    display: block!important;
  }
}

/* Tooltip */
.custom-tooltip {
  max-width: 328px;
  word-wrap: break-word;
  z-index: 99999;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.required-field {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;    
  color: #000000;
  margin-top: -14px;
  margin-bottom: -14px;
}
.error-field {
  border: 2px solid red;
  border-radius: var(--bs-border-radius);
}

.photo-rounded {
  width: 100px;      /* Ustaw odpowiednią szerokość */
  height: 100px;     /* Ustaw odpowiednią wysokość, taka sama jak szerokość */
  border-radius: 50%; /* Zaokrąglenie do kształtu koła */
  object-fit: cover;  /* Zapewnia, że obraz będzie dobrze wyglądał, nawet jeśli jego wymiary są inne niż kontenera */
}

.horizontal-line {
  width: 50px;      /* Długość linii */
  height: 2px;      /* Grubość linii */
  background-color: #40466a; /* Kolor linii */
  margin: 10px 0;   /* Opcjonalne marginesy wokół linii */
}
.indented-line {
    width: 98%;               /* Długość linii; możesz dostosować */
    height: 0.5px;            /* Grubość linii */
    background-color: grey; /* Kolor linii */
    margin: 10px auto;        /* Automatyczne marginesy z boków, aby wyśrodkować linię */
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.8), 
                inset 0 -1px 1px rgba(0, 0, 0, 0.4);
    display: block;           /* Ustawienie elementu jako blokowego, co umożliwia centrowanie */
}

/* Mój niestandardowy styl dla pola wprowadzania tekstu */
input.form-control:focus {
  box-shadow: none; /* Wyłącza cień na aktywnym polu */
  border-color: #ABC4F0; /* Domyślny kolor obramowania dla Bootstrap */
  border: 1px solid #ABC4F0;
}
textarea.form-control:focus {
  box-shadow: none; /* Wyłącza cień na aktywnym polu */
  border-color: #ABC4F0; /* Domyślny kolor obramowania dla Bootstrap */
  border: 1px solid #ABC4F0;
}
